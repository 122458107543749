// @flow

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Markdown from 'markdown-to-jsx';
import Divider from '@material-ui/core/Divider';
import { userInfo } from '../../database/userInfo';

const useStyle = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
}));

const mdOptions = {
  overrides: {
    p: {
      component: Typography,
      props: { variant: 'body2', align: 'justify' },
    },
  },
};

function About() {
  const classes = useStyle();

  return (
    <Grid container spacing={3} direction="column" alignItems="center">
      <Grid item xs={12} sm={10} md={8}>
        <Paper>
          <div className={classes.content}>
            <Typography variant="h4">Bio</Typography>
          </div>
          <Divider />
          <div className={classes.content}>
            <Markdown options={mdOptions}>{userInfo.description}</Markdown>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default About;
