// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import BlogPostCard from '../elements/BlogPostCard';
import type { BlogPostType } from '../../types/BlogPostType';
import allBlogPosts from '../../database/blogPosts.json';

function getSortedListPosts(posts) {
  // hack for flow to not yell: https://github.com/facebook/flow/issues/2133#issuecomment-362154595
  const list = Object.keys(posts).map((key) => posts[key]);
  return list.sort((a, b) => {
    return a.id < b.id ? 1 : -1;
  });
}

export default function BlogPostList() {
  const sortedPosts: BlogPostType[] = getSortedListPosts(allBlogPosts);
  return (
    <Grid container spacing={4}>
      {sortedPosts.map((blogPost: BlogPostType) => (
        <BlogPostCard key={blogPost.id} post={blogPost} />
      ))}
    </Grid>
  );
}
