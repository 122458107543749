// @flow
import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import NoMatch from './NoMatch';
import BlogMarkdown from '../shared/BlogMarkdown';
import type { BlogPostType } from '../../types/BlogPostType';
import allBlogPosts from '../../database/blogPosts.json';
import Tags from '../shared/Tags';

const useStyle = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
  },
  grid: {
    width: '100%',
  },
}));

export default function BlogPost() {
  const classes = useStyle();
  const { blogPostId } = useParams();
  if (!Object.prototype.hasOwnProperty.call(allBlogPosts, blogPostId)) {
    return <NoMatch />;
  }
  const blogPost: BlogPostType = allBlogPosts[blogPostId];
  const postHeader = `# ${blogPost.title}\n#### ${blogPost.date.toString()}`;
  const content = `${postHeader}\n\n${blogPost.content}`;
  return (
    <Grid container>
      <Grid item sm={false} md={1} lg={2} />
      <Grid item sm={12} md={10} lg={8}>
        <Paper elevation={3} className={classes.content}>
          <BlogMarkdown>{content}</BlogMarkdown>
          <Tags tags={blogPost.tags} />
        </Paper>
      </Grid>
      <Grid item sm={false} md={1} lg={2} />
    </Grid>
  );
}
