// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tag: {
    display: 'inline-block',
    border: '1px solid',
    padding: '5px',
    margin: '2px',
    color: 'black',
    borderColor: theme.palette.primary.light,
    borderRadius: '5px',
    fontSize: 11,
  },
  tags: {
    display: 'block',
    margin: '5px 0px 5px 0px',
  },
}));

type PropsType = {
  tags: string[],
};

export default function Tags(props: PropsType) {
  const { tags } = props;
  const classes = useStyles();
  const items = tags.map((tag) => (
    <span key={tag} className={classes.tag}>
      {tag}
    </span>
  ));
  return (
    <div className={classes.tags}>
      <div className="flex flex-row flex-wrap">{items}</div>
    </div>
  );
}
