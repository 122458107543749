// @flow
import React from 'react';
import type { SocialLinkType } from '../../types/SocialLinkType';
import { icons, iconKeys } from '../../icons/index';
import { socialLinkTypes } from '../../types/SocialLinkType';

const linkToIcon = {
  [socialLinkTypes.gitHub]: iconKeys.github,
  [socialLinkTypes.linkedIn]: iconKeys.linkedin,
  [socialLinkTypes.twitter]: iconKeys.twitter,
  [socialLinkTypes.scholar]: iconKeys.scholar,
};
const defaultProps = {
  color: 'inherit',
};

type IconProps = {
  ...SocialLinkType,
  color?: ?string,
};

function SocialIcon(props: IconProps) {
  const { type, color = defaultProps.color } = props;
  if (!Object.prototype.hasOwnProperty.call(linkToIcon, type)) {
    return type;
  }
  const iconKey = linkToIcon[type];
  const component = icons[iconKey];

  return <component.component color={color} />;
}

SocialIcon.defaultProps = defaultProps;

export default SocialIcon;
