// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme as fixMeCreateMuiTheme,
} from '@material-ui/core/styles';
import { amber, teal } from '@material-ui/core/colors';
import App from './components/App';

// FIXME: https://github.com/mui-org/material-ui/issues/13394, waiting for v5 to be released
const theme = fixMeCreateMuiTheme({
  palette: {
    primary: {
      main: amber[600],
    },
    secondary: {
      main: teal[700],
    },
  },
});

const appContainerId = 'root';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById(appContainerId),
);
