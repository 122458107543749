// @flow

import type { SocialLinkType } from '../types/SocialLinkType';

export type SocialMediaData = {|
  ...SocialLinkType,
  url: string,
|};

export const socialMediaData: SocialMediaData[] = [
  {
    type: 'LinkedIn',
    url: 'https://www.linkedin.com/in/marc-anoma-20b74940/',
  },
  {
    type: 'Twitter',
    url: 'https://twitter.com/anomamarc',
  },
  {
    type: 'Google Scholar',
    url:
      'https://scholar.google.co.jp/citations?user=RrWWCvQAAAAJ&hl=en&oi=sra',
  },
  {
    type: 'GitHub',
    url: 'https://github.com/anomam',
  },
];
