// @flow

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import NavBar from './NavBar';
import Copyright from './Copyright';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
  footer: {
    marginBottom: '20px',
  },
}));

type LayoutProps = {
  children: any,
};
const Layout = (props: LayoutProps) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <main>
      <header>
        <NavBar />
      </header>
      <section>
        <Container className={classes.container}>{children}</Container>
      </section>
      <footer className={classes.footer}>
        <Box pt={4}>
          <Copyright />
        </Box>
      </footer>
    </main>
  );
};

export default Layout;
