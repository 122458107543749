// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProjectCard from '../elements/ProjectCard';
import { projects } from '../../database/projects';

function Projects() {
  return (
    <Grid container spacing={3}>
      {projects.map((project) => (
        <Grid item key={project.title} xs={12} sm={6} md={3}>
          <ProjectCard project={project} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Projects;
