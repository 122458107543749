// @flow

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CodeIcon from '@material-ui/icons/Code';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import Tooltip from '@material-ui/core/Tooltip';
import MoreIcon from '@material-ui/icons/MoreVert';
import Link from '@material-ui/core/Link';
import { Link as RLink } from 'react-router-dom';
import { socialMediaData } from '../../database/socialMedia';
import SocialIcon from './SocialIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  usrIcon: {
    marginRight: theme.spacing(2),
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
    color: 'inherit',
  },
  title: {
    flexGrow: 1,
  },
  // make buttons disappear at certain breakpoints
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const linksData = [
  {
    name: 'Home',
    link: '/',
    Icon: HomeIcon,
  },
  {
    name: 'Projects',
    link: '/projects',
    Icon: ViewModuleIcon,
  },
  {
    name: 'Blog',
    link: '/blog',
    Icon: ViewHeadlineIcon,
  },
];

export default function NavBar() {
  const classes = useStyles();

  // Handling mobile menu
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const mobileMenuId = 'menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {linksData.map((data) => (
        <MenuItem key={data.name} component={RLink} to={data.link} replace>
          <IconButton color="inherit">
            <data.Icon color="primary" />
          </IconButton>
          {data.name}
        </MenuItem>
      ))}
      {socialMediaData.map((data) => (
        <MenuItem
          key={data.type}
          component={Link}
          href={data.url}
          className={classes.link}
        >
          <IconButton color="inherit">
            <SocialIcon type={data.type} color="primary" />
          </IconButton>
          {data.type}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <CodeIcon
            edge="start"
            className={classes.usrIcon}
            color="inherit"
            aria-label="menu"
          />
          <Typography variant="h6" className={classes.title}>
            anomam.github.io
          </Typography>
          <div className={classes.sectionDesktop}>
            {linksData.map((data) => (
              <Tooltip key={data.name} title={data.name}>
                <RLink to={data.link} className={classes.link} replace>
                  <IconButton color="inherit">
                    <data.Icon />
                  </IconButton>
                </RLink>
              </Tooltip>
            ))}
            {socialMediaData.map((data) => (
              <Tooltip key={data.type} title={data.type}>
                <IconButton color="inherit" href={data.url}>
                  <SocialIcon type={data.type} />
                </IconButton>
              </Tooltip>
            ))}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
