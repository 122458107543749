// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import type { BlogPostType } from '../../types/BlogPostType';
import Tags from '../shared/Tags';

type PropsType = {
  post: BlogPostType,
};

export default function BlogPostCard(props: PropsType) {
  const { post } = props;
  return (
    <Grid item xs={12}>
      <CardActionArea component={Link} to={`blog/${post.id}`}>
        <Card>
          <div>
            <CardContent>
              <Typography component="h2" variant="h5">
                {post.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {post.date}
              </Typography>
              <Tags tags={post.tags} />
              <Typography variant="subtitle1" paragraph>
                {post.summary}
              </Typography>
              <Typography variant="subtitle1" color="secondary">
                Continue reading...
              </Typography>
            </CardContent>
          </div>
        </Card>
      </CardActionArea>
    </Grid>
  );
}
