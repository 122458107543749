// @flow

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import About from './screens/About';
import Projects from './screens/Projects';
import NoMatch from './screens/NoMatch';
import BlogPost from './screens/BlogPost';
import BlogPostList from './screens/BlogPostList';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={About} />
      <Route path="/projects" exact component={Projects} />
      <Route path="/blog" exact component={BlogPostList} />
      <Route path="/blog/:blogPostId" exact component={BlogPost} />
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
}
