// @flow

import React, { useRef, useEffect } from 'react';
import hljs from 'highlight.js';
import ReactMarkdown from 'react-markdown';
import 'highlight.js/styles/agate.css';
import RemarkMathPlugin from 'remark-math';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css'; // necessary

type MapPropsType = {
  renderers: any,
};

// Create a custom markdown components
// capable of handling formulas with $...$ and $$ ... $$
// https://levelup.gitconnected.com/adding-katex-and-markdown-in-react-7b70694004ef
const mapProps: any = (props: MapPropsType) => ({
  ...props,
  escapeHtml: false,
  plugins: [RemarkMathPlugin],
  renderers: {
    ...props.renderers,
    math: ({ value }: any) => <BlockMath>{value}</BlockMath>,
    inlineMath: ({ value }: any) => <InlineMath>{value}</InlineMath>,
  },
});
const CustomMarkdown = (props) => <ReactMarkdown {...mapProps(props)} />;

type PropsType = {
  children: string,
};

export default function BlogMarkdown(props: PropsType) {
  const { children } = props;
  const rootRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.querySelectorAll('pre code').forEach((block) => {
        hljs.highlightBlock(block);
      });
    }
  }, [children]);

  return (
    <div ref={rootRef}>
      <CustomMarkdown>{children}</CustomMarkdown>
    </div>
  );
}
