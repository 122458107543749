// @flow
import type { ProjectType } from '../types/ProjectType';
import bifacial from './images/bifacial.jpg';
import radCooling from './images/passive_rad_cooling.jpg';
import parisMap from './images/Paris_map.png';
import robot from './images/eltorobravo.jpg';
import pendulum from './images/pendulum.gif';
import handwriting from './images/handwriting.png';

export const projects: ProjectType[] = [
  {
    title: 'Handwriting generation with RNNs',
    summary:
      'Combining RNNs with a mixture of gaussians to teach an algorithm how to handwrite.',
    description: '',
    tags: ['deep-learning', 'python', 'pytorch'],
    date: new Date(),
    image: {
      src: handwriting,
      caption: 'Unconditional generation',
    },
    links: [
      { to: 'https://github.com/anomam/handwriting-pytorch', name: 'Github' },
      { to: 'blog/1616716800', name: 'Blog Post' },
    ],
  },
  {
    title: 'Neural Style Transfer',
    summary:
      'Streamlit application for Neural Style Transfer using Keras and Tensorflow.',
    description: '',
    tags: ['deep-learning', 'python', 'tensorflow'],
    date: new Date(),
    image: {
      src:
        'https://github.com/anomam/nst/raw/master/outputs/example/starry_tokyo_tower.gif',
      caption: 'NST',
    },
    links: [
      { to: 'https://github.com/anomam/nst', name: 'Github' },
      {
        to: 'blog/1585353600',
        name: 'Blog Post',
      },
    ],
  },
  {
    title: 'Pendulum RL',
    summary:
      'Reinforcement Learning for pendulum equilibrium using numpy and value function iteration.',
    description: '',
    tags: ['reinforcement learning', 'numpy'],
    date: new Date(),
    image: {
      src: pendulum,
      caption: 'Pendulum RL',
    },
    links: [
      {
        to: 'blog/1543622400',
        name: 'Blog Post',
      },
    ],
  },
  {
    title: 'pvfactors',
    summary:
      'Irradiance modeling made simple: open-source Python model for bifacial irradiance modeling, published at IEEE PVSC 44 (2017).',
    description: `
        This is a Python package I developed at SunPower that originated from a mathematical model we validated experimentally, and which calculates bifacial irradiance and diffuse shading on photovoltaic (PV) arrays. I gave an oral presentation of this model at the IEEE PVSC 44 conference.
pvfactors makes that model open-source on Github, but the package has turned into something closer to a framework now, where users can plug in their own irradiance models, view factor calculations and even geometries.
pvfactors is also now used in the industry-standard and much larger open-source photovoltaic modeling package called pvlib (as of pvlib v0.6.1).
        `,
    tags: ['python', 'R&D', 'linear algebra'],
    date: new Date(),
    image: {
      src: bifacial,
      caption: 'pvfactors',
    },
    links: [
      { to: 'https://sunpower.github.io/pvfactors/', name: 'Github' },
      {
        to:
          'https://pdfs.semanticscholar.org/ebb2/35e3c3796b158e1a3c45b40954e60d876ea9.pdf',
        name: 'Paper',
      },
    ],
  },
  {
    title: 'Passive radiative cooling',
    summary:
      'Nature paper on first-time in the world daytime passive radiative cooling using a nanophotonics device.',
    description: '',
    tags: ['energy', 'renewable', 'R&D'],
    date: new Date(),
    image: {
      src: radCooling,
      caption: 'Passive radiative cooling',
    },
    links: [
      { to: 'https://www.nature.com/articles/nature13883', name: 'Paper' },
    ],
  },
  {
    title: 'Paris Real Estate Map',
    summary: 'Interactive map on Paris real estate prices using DVF data.',
    description: '',
    tags: ['angularjs', 'python', 'real-estate'],
    date: new Date(),
    image: {
      src: parisMap,
      caption: 'Paris real estate',
    },
    links: [{ to: 'https://www.marcanoma.com/dvf/index', name: 'Demo' }],
  },
  {
    title: 'El Toro Bravo',
    summary:
      'Fun mechatronics project where we built an autonomous robot for sumo battling during grad school at Stanford.',
    description: '',
    tags: ['mechatronics', 'grad school', 'stanford'],
    date: new Date(),
    image: {
      src: robot,
      caption: 'El Toro Bravo',
    },
    links: [
      {
        to: 'https://me210team10torobravo.weebly.com/',
        name: 'Website',
      },
      {
        to:
          'https://news.stanford.edu/news/2013/march/student-robot-duel-032013.html',
        name: 'Video',
      },
    ],
  },
];
