// @flow

// create key-value store for iconKey: icon component

import type { ComponentType } from 'react';

import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import SchoolIcon from '@material-ui/icons/School';
import GitHubIcon from '@material-ui/icons/GitHub';

export const iconKeys = {
  github: 'github',
  linkedin: 'linkedin',
  twitter: 'twitter',
  scholar: 'scholar',
};

type IconType = {|
  component: ComponentType<any>,
|};

export type IconsType = {
  [iconKey: $Values<typeof iconKeys>]: IconType,
};

export const icons: IconsType = {
  [iconKeys.github]: { component: GitHubIcon },
  [iconKeys.linkedin]: { component: LinkedInIcon },
  [iconKeys.twitter]: { component: TwitterIcon },
  [iconKeys.scholar]: { component: SchoolIcon },
};
