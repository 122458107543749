// @flow
import type { UserInfoType } from '../types/UserInfoType';

export const userInfo: UserInfoType = {
  firstName: 'Marc',
  lastName: 'Anoma',
  description: `
  My name is **Marc Abou Anoma**. I'm a data science and machine learning enthusiast living in **Tokyo**. 
  My educational background is in numerical modeling and scientific computing for energy systems, but I have broad interests in machine learning, reinforcement learning, 
  and full stack application development.  
  In my spare time I enjoy learning about new technical subjects, and above all I like to build things using what I learn. 
  So the goal here is to share these projects and thoughts with people with similar interests. 
  I may also occasionally post about non-technical subjects! You can find a list of those in the **Projects** and **Blog** sections of this website.  
  Please don't hesitate to reach out via comments or using the social medial links.  
  
  Thank you for your visit!
  `,
};
