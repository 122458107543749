// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Markdown from 'markdown-to-jsx';
import type { ProjectType } from '../../types/ProjectType';
import Tags from '../shared/Tags';
import SmartLink from '../shared/SmartLink';

type PropsType = {
  project: ProjectType,
};

const useStyles = makeStyles({
  card: {
    maxHeight: '400px',
  },
  media: {
    maxWidth: '100%',
    height: '140px',
  },
  link: {
    textDecoration: 'none',
    marginRight: '3px',
  },
});

const mdOptions = {
  overrides: {
    p: {
      component: Typography,
      props: { variant: 'body2', align: 'justify', color: 'textSecondary' },
    },
  },
};

export default function ProjectCard(props: PropsType) {
  const { project } = props;
  const classes = useStyles();
  // TODO: find a more elegant way to apply formatting
  // apparently formatting doesn't apply if one-liner
  const summaryHack = `  ${project.summary}`;
  // project links
  const linkButtons = project.links.map((link) => (
    <SmartLink link={link} className={classes.link}>
      <Button variant="contained" size="small" color="secondary">
        {link.name}
      </Button>
    </SmartLink>
  ));
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={project.image.caption}
          className={classes.media}
          image={project.image.src}
          title={project.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {project.title}
          </Typography>
          <Tags tags={project.tags} />
          <Markdown options={mdOptions}>{summaryHack}</Markdown>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <div>{linkButtons}</div>
      </CardActions>
    </Card>
  );
}
