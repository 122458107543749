// @flow

export const socialLinkTypes = {
  gitHub: 'GitHub',
  linkedIn: 'LinkedIn',
  twitter: 'Twitter',
  scholar: 'Google Scholar',
};

export type SocialLinkType = {|
  type: $Values<typeof socialLinkTypes>,
|};
