// @flow
import React from 'react';
import type { Node } from 'react';
// import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import type { LinkType } from '../../types/LinkType';

const defaultProps = {
  className: '',
};

type PropsType = {
  link: LinkType,
  children: Node,
  className?: string,
};

const SmartLink = (props: PropsType) => {
  const { link, children, className = '' } = props;
  const commonClasses = 'flex flex-row items-center';

  const isExternal = link.to.startsWith('http');
  const externalLink = (
    <a href={link.to} className={`${commonClasses} ${className}`}>
      {children}
    </a>
  );
  const internalLink = (
    <NavLink to={link.to} className={`${commonClasses} ${className}`} exact>
      {children}
    </NavLink>
  );

  return isExternal ? externalLink : internalLink;
};

SmartLink.defaultProps = defaultProps;

export default SmartLink;
